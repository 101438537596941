import React from 'react';
import { Box, Container, Typography, Paper, Divider, Link } from '@mui/material';
import i18next from '../../utilis/i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle.ts';

const TermsAndConditionsPage: React.FC = () => {
  useDocumentTitle(i18next.t('Views.TermsAndConditionsPage.Title'));

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          backgroundColor: 'rgba(18, 18, 18, 0.9)',
          border: '1px solid rgba(212, 175, 55, 0.3)',
          borderRadius: '8px',
        }}
      >
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography variant="h3" component="h1" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Title')}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            {i18next.t('Views.TermsAndConditions.LastUpdated', { date: 'April 2, 2025' })}
          </Typography>
        </Box>

        <Divider sx={{ mb: 4, backgroundColor: 'rgba(212, 175, 55, 0.3)' }} />

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Introduction.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Introduction.Content1')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Introduction.Content2')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Definitions.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 1 }}>
            <strong>Festportalen:</strong> {i18next.t('Views.TermsAndConditions.Definitions.Platform')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 1 }}>
            <strong>{i18next.t('Views.TermsAndConditions.Definitions.UserTerm')}:</strong> {i18next.t('Views.TermsAndConditions.Definitions.User')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 1 }}>
            <strong>{i18next.t('Views.TermsAndConditions.Definitions.ServiceProviderTerm')}:</strong> {i18next.t('Views.TermsAndConditions.Definitions.ServiceProvider')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 1 }}>
            <strong>{i18next.t('Views.TermsAndConditions.Definitions.ContentTerm')}:</strong> {i18next.t('Views.TermsAndConditions.Definitions.Content')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.AccountTerms.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.AccountTerms.Responsibility')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.AccountTerms.Accuracy')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.AccountTerms.Termination')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.UserConduct.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.UserConduct.Prohibited')}
          </Typography>
          <Typography variant="body1" component="div" sx={{ color: '#fff', mb: 2 }}>
            <ul>
              <li>{i18next.t('Views.TermsAndConditions.UserConduct.List.Illegal')}</li>
              <li>{i18next.t('Views.TermsAndConditions.UserConduct.List.Impersonation')}</li>
              <li>{i18next.t('Views.TermsAndConditions.UserConduct.List.Harassment')}</li>
              <li>{i18next.t('Views.TermsAndConditions.UserConduct.List.Abuse')}</li>
              <li>{i18next.t('Views.TermsAndConditions.UserConduct.List.Security')}</li>
            </ul>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.IntellectualProperty.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.IntellectualProperty.Rights')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.IntellectualProperty.License')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Privacy.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Privacy.Policy')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Disclaimer.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Disclaimer.AsIs')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Disclaimer.Liability')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Changes.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Changes.Content')}
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="h2" sx={{ color: '#D4AF37', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Contact.Title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff', mb: 2 }}>
            {i18next.t('Views.TermsAndConditions.Contact.Content')}
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <RouterLink to="/" style={{ textDecoration: 'none' }}>
            <Link component="button" sx={{ color: '#D4AF37' }}>
              {i18next.t('Views.TermsAndConditions.BackToHome')}
            </Link>
          </RouterLink>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsAndConditionsPage;
