import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Snackbar,
  Alert as MuiAlert,
  AlertProps
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { register as registerUser, selectAuth, checkEmailExists, checkPhoneExists } from '../../store/slices/authSlice';
import { AppDispatch, RootState } from '../../store/store';
import { unwrapResult } from '@reduxjs/toolkit';
import i18next from '../../utilis/i18next';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FlagIcon } from 'react-flag-kit';
import { countryCodes } from '../../utilis/countryCodes';
import { Link as RouterLink } from 'react-router-dom';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber: string;
  countryCode: string;
  gender: string;
  preferredTitle: string;
  acceptedTerms: boolean;
};

const countryCodes = [
  { code: '+47', country: 'NO', name: 'Norway' },
  { code: '+45', country: 'DK', name: 'Denmark' },
  { code: '+46', country: 'SE', name: 'Sweden' },
  { code: '+358', country: 'FI', name: 'Finland' },
  { code: '+354', country: 'IS', name: 'Iceland' },
  { code: '+44', country: 'GB', name: 'United Kingdom' },
  { code: '+1', country: 'US', name: 'United States' },
  { code: '+49', country: 'DE', name: 'Germany' },
  { code: '+33', country: 'FR', name: 'France' },
  { code: '+39', country: 'IT', name: 'Italy' },
  { code: '+34', country: 'ES', name: 'Spain' },
  { code: '+31', country: 'NL', name: 'Netherlands' },
  { code: '+387', country: 'BA', name: 'Bosnia and Herzegovina' },
];

const RegisterPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailCheckLoading, setEmailCheckLoading] = useState(false);
  const [phoneCheckLoading, setPhoneCheckLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const redirectTo = params.get('redirectTo') || '/';
  useDocumentTitle(i18next.t('Views.RegisterPage.Title'));

  const auth = useSelector((state: RootState) => state.auth);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    trigger
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      countryCode: '',
      gender: '',
      preferredTitle: '',
      acceptedTerms: false
    },
    mode: 'onBlur'
  });

  const password = watch('password');
  const email = watch('email');

  const getGenericErrorMessage = (error: string) => {
    return error;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth.status === 'succeeded' && auth.success) {
      setRegistrationComplete(true);
      
      // Set a timeout to redirect after 5 seconds
      setTimeout(() => {
        navigate(redirectTo);
      }, 5000);
    } else if (auth.status === 'failed' && auth.error) {
      setShowError(true);
      setErrorMessage(auth.error);
      
      // Check if the error is related to email already existing
      if (auth.error.includes('email') && auth.error.includes('already')) {
        setEmailExists(true);
        setError('email', { 
          type: 'manual', 
          message: i18next.t('Views.RegisterPage.EmailAlreadyExists') 
        });
      }
      
      // Check if the error is related to phone number already existing
      if (auth.error.includes('phone') && auth.error.includes('already')) {
        setPhoneExists(true);
        setError('phoneNumber', { 
          type: 'manual', 
          message: i18next.t('Views.RegisterPage.PhoneAlreadyExists') 
        });
      }
    }
  }, [auth, setError, navigate, redirectTo]);

  const onSubmit = async (data: FormValues) => {
    // Check if email already exists before submitting
    if (emailExists) {
      setError('email', { 
        type: 'manual', 
        message: i18next.t('Views.RegisterPage.EmailAlreadyExists') 
      });
      return;
    }
    
    if (phoneExists) {
      setError('phoneNumber', { 
        type: 'manual', 
        message: i18next.t('Views.RegisterPage.PhoneAlreadyExists') 
      });
      return;
    }
    
    // Perform one final check for email existence
    await checkEmail(data.email);
    if (emailExists) {
      return;
    }
    
    // Only check phone if it's provided
    if (data.phoneNumber) {
      // Ensure country code is provided if phone number is entered
      if (!data.countryCode) {
        setError('countryCode', {
          type: 'manual',
          message: i18next.t('Views.RegisterPage.CountryCodeRequired') || 'Country code is required when phone number is provided'
        });
        return;
      }
      
      await checkPhone(data.phoneNumber, data.countryCode);
      if (phoneExists) {
        return;
      }
    }

    // Prepare registration data
    const registrationData = {
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      gender: data.gender,
      preferredTitle: data.preferredTitle,
      acceptedTerms: data.acceptedTerms
    };

    // Only include phone number and country code if phone number is provided
    if (data.phoneNumber) {
      Object.assign(registrationData, {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode
      });
    }

    dispatch(registerUser(registrationData));
  };

  const handleCloseError = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  // Function to check if email already exists
  const checkEmail = async (email: string) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return;
    }
    
    setEmailCheckLoading(true);
    try {
      const resultAction = await dispatch(checkEmailExists(email));
      const result = unwrapResult(resultAction);
      setEmailExists(result.exists);
      if (result.exists) {
        setError('email', { 
          type: 'manual', 
          message: i18next.t('Views.RegisterPage.EmailAlreadyExists') 
        });
      } else {
        if (errors.email?.type === 'manual') {
          clearErrors('email');
        }
      }
    } catch (error) {
      console.error('Failed to check email:', error);
    } finally {
      setEmailCheckLoading(false);
    }
  };

  // Function to check if phone number already exists
  const checkPhone = async (phoneNumber: string, countryCode: string) => {
    if (!phoneNumber) {
      return;
    }
    
    setPhoneCheckLoading(true);
    try {
      const resultAction = await dispatch(checkPhoneExists({ phoneNumber, countryCode }));
      const result = unwrapResult(resultAction);
      setPhoneExists(result.exists);
      if (result.exists) {
        setError('phoneNumber', { 
          type: 'manual', 
          message: i18next.t('Views.RegisterPage.PhoneAlreadyExists') 
        });
      } else {
        if (errors.phoneNumber?.type === 'manual') {
          clearErrors('phoneNumber');
        }
      }
    } catch (error) {
      console.error('Failed to check phone:', error);
    } finally {
      setPhoneCheckLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.92)), url('/images/banner.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'radial-gradient(circle at 20% 120%, rgba(212, 175, 55, 0.15) 0%, rgba(212, 175, 55, 0) 70%)',
        },
      }}
    >
      {registrationComplete ? (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 1000,
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 80,
              color: '#D4AF37',
              mb: 3,
              animation: 'fadeIn 0.5s ease-in',
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'scale(0.8)' },
                to: { opacity: 1, transform: 'scale(1)' },
              },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              color: '#D4AF37',
              mb: 2,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.2s',
              animationFillMode: 'both',
            }}
          >
            {i18next.t('Views.RegisterPage.RegistrationSuccess')}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              mb: 2,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.3s',
              animationFillMode: 'both',
            }}
          >
            {i18next.t('Views.RegisterPage.CheckEmail')}
          </Typography>
          <Typography
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in 0.4s',
              animationFillMode: 'both',
              maxWidth: '500px',
              px: 2,
            }}
          >
            {i18next.t('Views.RegisterPage.CheckEmailDetails', { email: email })}
          </Typography>
          <Typography
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              textAlign: 'center',
              mt: 4,
              animation: 'fadeIn 0.5s ease-in 0.5s',
              animationFillMode: 'both',
            }}
          >
            {i18next.t('Views.RegisterPage.RedirectMessage')}
          </Typography>
        </Box>
      ) : (
        <Container
          component="main"
          maxWidth="md"
          sx={{
            pt: { xs: 4, sm: 8 },
            pb: 4,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Box
            mt={10}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              animation: 'fadeIn 0.8s ease-out',
              '@keyframes fadeIn': {
                from: { opacity: 0, transform: 'translateY(20px)' },
                to: { opacity: 1, transform: 'translateY(0)' },
              },
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{
                mb: 4,
                fontWeight: 600,
                textAlign: 'center',
                background:
                  'linear-gradient(to right, #e0ae55 0%, #FFD700 50%, #e0ae55 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundSize: '200% auto',
                animation: 'shine 3s linear infinite, slideDown 0.8s ease-out',
                '@keyframes shine': {
                  to: {
                    backgroundPosition: '200% center',
                  },
                },
                '@keyframes slideDown': {
                  from: {
                    opacity: 0,
                    transform: 'translateY(-20px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'translateY(0)',
                  },
                },
              }}
            >
              {i18next.t('Views.RegisterPage.Title')}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                width: '100%',
                background: 'rgba(0, 0, 0, 0.6)',
                backdropFilter: 'blur(10px)',
                p: 3,
                borderRadius: 1,
                border: '1px solid rgba(212, 175, 55, 0.3)',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
                position: 'relative',
                overflow: 'hidden',
                animation: 'formAppear 0.8s ease-out 0.2s both',
                '@keyframes formAppear': {
                  from: {
                    opacity: 0,
                    transform: 'scale(0.95)',
                    filter: 'blur(5px)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'scale(1)',
                    filter: 'blur(0)',
                  },
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ 
                      required: 'First name is required',
                      minLength: {
                        value: 2,
                        message: 'First name must be at least 2 characters'
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label={i18next.t('Views.RegisterPage.FirstNameLabel')}
                        autoComplete="given-name"
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            '& fieldset': {
                              borderColor: errors.firstName ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover fieldset': {
                              borderColor: errors.firstName ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: errors.firstName ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: errors.firstName ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-focused': {
                              color: errors.firstName ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#fff',
                          },
                          '& .MuiFormHelperText-root': {
                            color: 'error.main',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ 
                      required: 'Last name is required',
                      minLength: {
                        value: 2,
                        message: 'Last name must be at least 2 characters'
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label={i18next.t('Views.RegisterPage.LastNameLabel')}
                        autoComplete="family-name"
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            '& fieldset': {
                              borderColor: errors.lastName ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover fieldset': {
                              borderColor: errors.lastName ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: errors.lastName ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: errors.lastName ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-focused': {
                              color: errors.lastName ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#fff',
                          },
                          '& .MuiFormHelperText-root': {
                            color: 'error.main',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ 
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      },
                      validate: async (value) => {
                        if (emailExists) {
                          return i18next.t('Views.RegisterPage.EmailAlreadyExists');
                        }
                        await checkEmail(value);
                        if (emailExists) {
                          return i18next.t('Views.RegisterPage.EmailAlreadyExists');
                        }
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={i18next.t('Views.RegisterPage.EmailLabel')}
                        autoComplete="email"
                        error={!!errors.email || emailExists}
                        helperText={errors.email?.message || (emailExists ? i18next.t('Views.RegisterPage.EmailAlreadyExists') : '')}
                        onBlur={(e) => {
                          field.onBlur();
                          checkEmail(e.target.value);
                        }}
                        onChange={(e) => {
                          field.onChange(e);
                          if (emailExists) {
                            // Clear the manual error when user starts typing again
                            if (errors.email?.type === 'manual') {
                              clearErrors('email');
                            }
                            setEmailExists(false);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {emailCheckLoading && (
                                <CircularProgress size={20} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            '& fieldset': {
                              borderColor: errors.email ? 'error.main' : emailExists ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover fieldset': {
                              borderColor: errors.email ? 'error.main' : emailExists ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: errors.email ? 'error.main' : emailExists ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: errors.email ? 'error.main' : emailExists ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-focused': {
                              color: errors.email ? 'error.main' : emailExists ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#fff',
                          },
                          '& .MuiFormHelperText-root': {
                            color: 'error.main',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Controller
                      name="countryCode"
                      control={control}
                      defaultValue="+47"
                      rules={{ 
                        required: {
                          value: !!watch('phoneNumber'),
                          message: i18next.t('Views.RegisterPage.CountryCodeRequired') || 'Country code is required when phone number is provided'
                        }
                      }}
                      render={({ field }) => (
                        <FormControl sx={{ width: '140px', mr: 1, mt: 2 }}>
                          <InputLabel id="country-code-label" sx={{ 
                            color: errors.countryCode ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-focused': {
                              color: errors.countryCode ? 'error.main' : '#D4AF37',
                            },
                          }}>
                            {i18next.t('Views.RegisterPage.CountryCodeLabel')}
                          </InputLabel>
                          <Select
                            {...field}
                            labelId="country-code-label"
                            id="country-code"
                            label={i18next.t('Views.RegisterPage.CountryCodeLabel')}
                            error={!!errors.countryCode}
                            sx={{
                              height: '56px', // Match TextField height
                              '& .MuiOutlinedInput-root': {
                                color: '#fff',
                              },
                              '& .MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                color: '#fff',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: errors.countryCode ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: errors.countryCode ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: errors.countryCode ? 'error.main' : '#D4AF37',
                              },
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              // Check phone number again with new country code if there's a value
                              const phoneNumber = watch('phoneNumber');
                              if (phoneNumber) {
                                checkPhone(phoneNumber, e.target.value as string);
                              }
                            }}
                          >
                            {countryCodes.map((countryCode) => (
                              <MenuItem key={countryCode.code} value={countryCode.code} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <FlagIcon code={countryCode.country} style={{ marginRight: 8, width: 24 }} />
                                  <span>{countryCode.code}</span>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{ 
                        pattern: {
                          value: /^[0-9\s]*$/,
                          message: i18next.t('Views.RegisterPage.PhoneNumberFormat') || 'Phone number can only contain numbers and spaces'
                        },
                        validate: async (value) => {
                          // Skip validation if no phone number is provided
                          if (!value) return true;
                          
                          if (phoneExists) {
                            return i18next.t('Views.RegisterPage.PhoneAlreadyExists');
                          }
                          
                          // Make sure country code is provided if phone number is entered
                          const countryCode = watch('countryCode');
                          if (!countryCode) {
                            return i18next.t('Views.RegisterPage.CountryCodeRequired') || 'Country code is required when phone number is provided';
                          }
                          
                          await checkPhone(value, countryCode);
                          if (phoneExists) {
                            return i18next.t('Views.RegisterPage.PhoneAlreadyExists');
                          }
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="normal"
                          type={'tel'}
                          fullWidth
                          id="phoneNumber"
                          label={i18next.t('Views.RegisterPage.PhoneNumberLabel') || "Phone Number (optional)"}
                          autoComplete="tel"
                          error={!!errors.phoneNumber || phoneExists}
                          helperText={errors.phoneNumber?.message || (phoneExists ? i18next.t('Views.RegisterPage.PhoneAlreadyExists') : '')}
                          inputProps={{ 
                            pattern: '[0-9\\s]*',
                            inputMode: 'numeric'
                          }}
                          onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            // Allow only numbers and spaces
                            if (
                              !(
                                (charCode >= 48 && charCode <= 57) || // 0-9
                                charCode === 32   // space
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            field.onBlur();
                            const countryCode = watch('countryCode');
                            checkPhone(e.target.value, countryCode);
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                            if (phoneExists) {
                              // Clear the manual error when user starts typing again
                              if (errors.phoneNumber?.type === 'manual') {
                                clearErrors('phoneNumber');
                              }
                              setPhoneExists(false);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {phoneCheckLoading && (
                                  <CircularProgress size={20} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              color: '#fff',
                              '& fieldset': {
                                borderColor: errors.phoneNumber ? 'error.main' : phoneExists ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                              },
                              '&:hover fieldset': {
                                borderColor: errors.phoneNumber ? 'error.main' : phoneExists ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: errors.phoneNumber ? 'error.main' : phoneExists ? 'error.main' : '#D4AF37',
                              },
                            },
                            '& .MuiInputLabel-root': {
                              color: errors.phoneNumber ? 'error.main' : phoneExists ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                              '&.Mui-focused': {
                                color: errors.phoneNumber ? 'error.main' : phoneExists ? 'error.main' : '#D4AF37',
                              },
                            },
                            '& .MuiInputBase-input': {
                              color: '#fff',
                            },
                            '& .MuiFormHelperText-root': {
                              color: 'error.main',
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    rules={{ 
                      required: 'Date of birth is required',
                      validate: value => {
                        const date = new Date(value);
                        const today = new Date();
                        const age = today.getFullYear() - date.getFullYear();
                        const monthDiff = today.getMonth() - date.getMonth();
                        
                        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
                          return age - 1 >= 18 ? true : 'You must be at least 18 years old';
                        }
                        
                        return age >= 18 ? true : 'You must be at least 18 years old';
                      }
                    }}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format={'DD/MM/YYYY'}
                          onChange={(date: Dayjs | null) => {
                            if (date) field.onChange(date.format('YYYY-MM-DD'));
                          }}
                          value={field.value ? dayjs(field.value) : null}
                          label={i18next.t('Views.RegisterPage.DateOfBirthLabel')}
                          slotProps={{
                            textField: {
                              margin: "normal",
                              required: true,
                              fullWidth: true,
                              id: "dateOfBirth",
                              error: !!errors.dateOfBirth,
                              helperText: errors.dateOfBirth?.message,
                              sx: {
                                '& .MuiOutlinedInput-root': {
                                  color: '#fff',
                                  '& fieldset': {
                                    borderColor: errors.dateOfBirth ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: errors.dateOfBirth ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: errors.dateOfBirth ? 'error.main' : '#D4AF37',
                                  },
                                },
                                '& .MuiInputLabel-root': {
                                  color: errors.dateOfBirth ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                                  '&.Mui-focused': {
                                    color: errors.dateOfBirth ? 'error.main' : '#D4AF37',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  color: '#fff',
                                },
                                '& .MuiFormHelperText-root': {
                                  color: 'error.main',
                                },
                              }
                            },
                            popper: {
                              sx: {
                                '& .MuiPaper-root': {
                                  backgroundColor: '#121212',
                                  color: '#D4AF37',
                                  border: '2px solid rgba(212, 175, 55, 0.3)',
                                  '& .MuiPickersDay-root': {
                                    color: '#D4AF37',
                                    '&:hover': {
                                      backgroundColor: 'rgba(212, 175, 55, 0.2)',
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#D4AF37',
                                      color: '#000',
                                    },
                                  },
                                  '& .MuiDayCalendar-weekDayLabel': {
                                    color: 'rgba(212, 175, 55, 0.7)',
                                  },
                                  '& .MuiPickersCalendarHeader-label': {
                                    color: '#D4AF37',
                                  },
                                  '& .MuiPickersCalendarHeader-switchViewButton': {
                                    color: '#D4AF37',
                                  },
                                  '& .MuiPickersArrowSwitcher-button': {
                                    color: '#D4AF37',
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="preferredTitle"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%', mt: 2 }}>
                        <InputLabel id="preferred-title-label" sx={{ 
                          color: errors.preferredTitle ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                          '&.Mui-focused': {
                            color: errors.preferredTitle ? 'error.main' : '#D4AF37',
                          },
                        }}>
                          {i18next.t('Views.RegisterPage.PreferredTitleLabel')}
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="preferred-title-label"
                          id="preferred-title"
                          label={i18next.t('Views.RegisterPage.PreferredTitleLabel')}
                          error={!!errors.preferredTitle}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              color: '#fff',
                            },
                            '& .MuiSelect-select': {
                              display: 'flex',
                              alignItems: 'center',
                              color: '#fff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.preferredTitle ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.preferredTitle ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.preferredTitle ? 'error.main' : '#D4AF37',
                            },
                          }}
                        >
                          <MenuItem value="" sx={{ display: 'flex', alignItems: 'center' }}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Mr" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.TitleMr')}
                          </MenuItem>
                          <MenuItem value="Mrs" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.TitleMrs')}
                          </MenuItem>
                          <MenuItem value="Ms" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.TitleMs')}
                          </MenuItem>
                          <MenuItem value="Mx" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.TitleMx')}
                          </MenuItem>
                          <MenuItem value="Dr" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.TitleDr')}
                          </MenuItem>
                          <MenuItem value="Other" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.TitleOther')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%', mt: 2 }}>
                        <InputLabel id="gender-label" sx={{ 
                          color: errors.gender ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                          '&.Mui-focused': {
                            color: errors.gender ? 'error.main' : '#D4AF37',
                          },
                        }}>
                          {i18next.t('Views.RegisterPage.GenderLabel')}
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="gender-label"
                          id="gender"
                          label={i18next.t('Views.RegisterPage.GenderLabel')}
                          error={!!errors.gender}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              color: '#fff',
                            },
                            '& .MuiSelect-select': {
                              display: 'flex',
                              alignItems: 'center',
                              color: '#fff',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.gender ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.gender ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.gender ? 'error.main' : '#D4AF37',
                            },
                          }}
                        >
                          <MenuItem value="" sx={{ display: 'flex', alignItems: 'center' }}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="male" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.MaleOption')}
                          </MenuItem>
                          <MenuItem value="female" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.FemaleOption')}
                          </MenuItem>
                          <MenuItem value="other" sx={{ display: 'flex', alignItems: 'center' }}>
                            {i18next.t('Views.RegisterPage.OtherOption')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ 
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must be at least 8 characters'
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        required
                        fullWidth
                        label={i18next.t('Views.RegisterPage.PasswordLabel')}
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            '& fieldset': {
                              borderColor: errors.password ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover fieldset': {
                              borderColor: errors.password ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: errors.password ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: errors.password ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-focused': {
                              color: errors.password ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#fff',
                          },
                          '& .MuiFormHelperText-root': {
                            color: 'error.main',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{ 
                      required: 'Please confirm your password',
                      validate: value => value === password || 'Passwords do not match'
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        required
                        fullWidth
                        label={i18next.t('Views.RegisterPage.ConfirmPasswordLabel')}
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            '& fieldset': {
                              borderColor: errors.confirmPassword ? 'error.main' : 'rgba(212, 175, 55, 0.3)',
                            },
                            '&:hover fieldset': {
                              borderColor: errors.confirmPassword ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: errors.confirmPassword ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: errors.confirmPassword ? 'error.main' : 'rgba(255, 255, 255, 0.7)',
                            '&.Mui-focused': {
                              color: errors.confirmPassword ? 'error.main' : '#D4AF37',
                            },
                          },
                          '& .MuiInputBase-input': {
                            color: '#fff',
                          },
                          '& .MuiFormHelperText-root': {
                            color: 'error.main',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, color: 'rgba(255, 255, 255, 0.7)' }}>
                    <Controller
                      name="acceptedTerms"
                      control={control}
                      rules={{ 
                        required: 'You must accept the terms and privacy policy'
                      }}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          required
                          sx={{
                            color: errors.acceptedTerms ? 'error.main' : 'rgba(212, 175, 55, 0.5)',
                            '&.Mui-checked': {
                              color: '#D4AF37',
                            },
                          }}
                        />
                      )}
                    />
                    <Typography variant="body2">
                      {i18next.t('Views.RegisterPage.AcceptTerms')}{' '}
                      <Link component={RouterLink} to="/terms-and-conditions" target="_blank" sx={{ color: '#D4AF37' }}>
                        {i18next.t('Views.RegisterPage.TermsLink')}
                      </Link>{' '}
                      {i18next.t('Views.RegisterPage.And')}{' '}
                      <Link component={RouterLink} to="/privacy-policy" target="_blank" sx={{ color: '#D4AF37' }}>
                        {i18next.t('Views.RegisterPage.PrivacyLink')}
                      </Link>
                    </Typography>
                  </Box>
                  {errors.acceptedTerms && (
                    <Typography variant="caption" sx={{ color: 'error.main', ml: 2 }}>
                      {errors.acceptedTerms.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: '#D4AF37',
                      borderColor: '#D4AF37',
                      borderWidth: '2px',
                      backgroundColor: 'rgba(212, 175, 55, 0.05)',
                      textTransform: 'none',
                      fontSize: '1.1rem',
                      fontWeight: 500,
                      padding: '10px',
                      '&:hover': {
                        borderColor: '#D4AF37',
                        backgroundColor: 'rgba(212, 175, 55, 0.1)',
                        borderWidth: '2px',
                      },
                      position: 'relative',
                      overflow: 'hidden',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: '-100%',
                        width: '200%',
                        height: '100%',
                        background:
                          'linear-gradient(90deg, transparent, rgba(212, 175, 55, 0.2), transparent)',
                        animation: 'shimmer 2s infinite',
                      },
                      '@keyframes shimmer': {
                        '0%': {
                          left: '-100%',
                        },
                        '100%': {
                          left: '100%',
                        },
                      },
                    }}
                  >
                    {i18next.t('Views.RegisterPage.RegisterButton')}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link
                        href="/login"
                        variant="body2"
                        sx={{
                          color: '#D4AF37',
                          textDecoration: 'none',
                          opacity: 0.8,
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            opacity: 1,
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {i18next.t('Views.RegisterPage.AlreadyHaveAccount')}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Snackbar
              open={showError}
              autoHideDuration={6000}
              onClose={handleCloseError}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Alert
                onClose={handleCloseError}
                severity="error"
                sx={{
                  width: '100%',
                  backgroundColor: 'rgba(211, 47, 47, 0.95)',
                  color: '#fff',
                  backdropFilter: 'blur(10px)',
                }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default RegisterPage;
